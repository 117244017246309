/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  useEffect(() => {
    const script = document.createElement('script')

    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`
    script.async = true
    script.id = 'recaptcha_link'

    document.body.appendChild(script)

    return () => {
      const script = document.getElementById('recaptcha_link')
      const recaptchaBadge = document.querySelectorAll('.grecaptcha-badge')
      script?.remove()
      recaptchaBadge.forEach((element) => {
        element?.remove()
      })
    }
  }, [])

  // 'Wrong email format'
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.EMAIL'}))
      .max(50, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.MAX_LENGTH'}, {max: '50'}))
      .required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    password: Yup.string()
      .min(8, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.MIN_LENGTH'}, {min: '8'}))
      .max(50, intl.formatMessage({id: 'FORM.INPUT.VALIDATION.MAX_LENGTH'}, {max: '50'}))
      .required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      //@ts-ignore
      grecaptcha.ready(function () {
        //@ts-ignore
        grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'login'})
          .then(async function (token: string) {
            // Add your logic to submit to your backend server here.
            try {
              const {data: auth} = await login(values.email, values.password, token)
              saveAuth(auth)
              const {data: user} = await getUserByToken(auth.api_token)
              setCurrentUser(user)
            } catch (error) {
              console.error(error)
              saveAuth(undefined)
              setStatus('Os detalhes de login estão incorretos')
              setSubmitting(false)
              setLoading(false)
            }
          })
      })
    },
  })

  return (
    <>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid '>
        <div className='d-flex flex-lg-row-fluid'>
          {/*begin::Content*/}
          <div className='d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100'>
            {/*begin::Image*/}
            <img
              className='theme-light-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
              src='/media/logos/taskrush.svg'
              alt=''
            />
            <img
              className='theme-dark-show mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20'
              src='/media/logos/taskrush.svg'
              alt=''
            />
            {/*end::Image*/}

            {/*begin::Title*/}
            <h1 className='text-gray-800 fs-2qx fw-bold text-center mb-7'>
              {intl.formatMessage({id: 'AUTH.LOGIN.BIO'})}
            </h1>
            {/*end::Title*/}
          </div>
          {/*end::Content*/}
        </div>

        <div className='d-flex flex-column flex-lg-row flex-column-fluid justify-content-lg-end'>
          {/*begin::Wrapper*/}
          <div className='bg-body d-flex flex-column flex-center rounded-4 w-md-500px p-8'>
            {/*begin::Content*/}
            <div className='d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px'>
              {/*begin::Wrapper*/}
              <div className='d-flex flex-center flex-column flex-column-fluid pb-15 pb-lg-10'>
                {/*begin::Form*/}
                <form
                  className='form w-100'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {/*begin::Heading*/}
                  <div className='text-center mb-11'>
                    {/*begin::Title*/}
                    <h1 className='text-dark fw-bolder mb-3'>
                      {intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}
                    </h1>
                    {/*end::Title*/}

                    {/*begin::Subtitle*/}
                    <div className='text-gray-500 fw-semibold fs-6'>
                      {intl.formatMessage({id: 'AUTH.LOGIN.CREDENTIALS'})}
                    </div>
                    {/*end::Subtitle-*/}
                  </div>
                  {/*begin::Heading*/}

                  {/*begin::Separator*/}
                  <div className='separator separator-content my-14'>
                    <span className='w-125px text-gray-500 fw-semibold fs-7'>
                      {intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}
                    </span>
                  </div>
                  {/*end::Separator*/}
                  {formik.status && (
                    <div className='mb-lg-15 alert alert-danger'>
                      <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                  )}
                  {/* begin::Form group */}
                  <div className='fv-row mb-8'>
                    {/* <label className='form-label fs-6 text-dark'>
                      {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
                    </label> */}
                    <input
                      placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
                      {...formik.getFieldProps('email')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.email && formik.errors.email},
                        {
                          'is-valid': formik.touched.email && !formik.errors.email,
                        }
                      )}
                      type='email'
                      name='email'
                      autoComplete='off'
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container invalid-feedback'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    )}
                  </div>
                  {/* end::Form group */}

                  <div className='fv-row mb-3'>
                    {/* <label className='form-label text-dark fs-6 mb-0'>
                      {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                    </label> */}
                    <input
                      {...formik.getFieldProps('password')}
                      placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                      type='password'
                      name='password'
                      autoComplete='off'
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>

                  {/*begin::Wrapper*/}
                  <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                    <div />

                    {/* begin::Link */}
                    <Link to='/auth/forgot-password' className='link-primary'>
                      {intl.formatMessage({id: 'AUTH.FORGOT.TITLE'})}
                    </Link>
                    {/* end::Link */}
                  </div>
                  {/*end::Wrapper*/}

                  {/*begin::Submit button*/}
                  <div className='d-grid mb-10'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && (
                        <span className='indicator-label'>
                          {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}
                        </span>
                      )}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          {intl.formatMessage({id: 'AUTH.GENERAL.LOADING'})}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  {/*end::Submit button*/}
                </form>
                {/*end::Form*/}
              </div>
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Wrapper*/}
        </div>
      </div>
    </>
  )
}
