import {ID, Response} from '../../../../../../../../../_metronic/helpers'

export type TaskComment = {
  id?: ID
  task_id?: ID
  user_id?: ID
  user_name?: string
  content?: string
}
export type ResponseTaskComment = {
  comment: TaskComment
  message?: string
  errors?: boolean
}

export type TaskCommentsQueryResponse = Response<Array<TaskComment>>

export const initialTaskComment: TaskComment = {
  task_id: undefined,
  content: '',
}
