import {FC, useCallback, useEffect, useState} from 'react'
import {ID, isNotEmpty, QUERIES, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useTheme, styled} from '@mui/material/styles'
import Popper from '@mui/material/Popper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete'
import InputBase from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import {useMutation, useQueryClient} from 'react-query'
import {useFormik} from 'formik'
import {createTaskAttachement, deliveryTask, updateNameTask} from './core/_requests'
import {Toast} from '../../../../../_metronic/partials/widgets'
import {taskresponsible_adjust} from './TaskUsers/core/_requests'
import {deleteContract} from '../../../contracts/components/contracts-list/core/_requests'

export interface IconUserModel {
  name: string
  avatar?: string
  color?: string
  initials?: string
  task_responsible_id?: ID
  user_id: ID
}

type Props = {
  users: Array<IconUserModel>
  usersAll: Array<IconUserModel>
  classes?: string
  taskId?: ID
  isReadonly?: boolean
}

interface PopperComponentProps {
  anchorEl?: any
  disablePortal?: boolean
  open: boolean
}

const StyledAutocompletePopper = styled('div')(({theme}) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}))

function PopperComponent(props: PopperComponentProps) {
  const {disablePortal, anchorEl, open, ...other} = props
  return <StyledAutocompletePopper {...other} />
}
//@ts-ignore
const StyledPopper = styled(Popper)(({theme}) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}))

const StyledInput = styled(InputBase)(({theme}) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'
      }`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}))

const UsersSymbolGroupColumn: FC<Props> = ({
  users,
  usersAll,
  classes,
  taskId,
  isReadonly = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [value, setValue] = useState<IconUserModel[]>(usersAll)
  const [pendingValue, setPendingValue] = useState<IconUserModel[]>([])
  const theme = useTheme()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isReadonly) return

    users.map((user) => {
      if (user.task_responsible_id != null) {
        setPendingValue((value) => [...value, user])
      }
    })
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setValue(pendingValue)
    if (anchorEl) {
      anchorEl.focus()
    }
    setAnchorEl(null)
  }

  const queryClient = useQueryClient()

  const open = Boolean(anchorEl)
  const intl = useIntl()

  return (
    <>
      <div className={`symbol symbol-group symbol-hover ${classes}`} onClick={handleClick}>
        {users &&
          users.map((user, i) => {
            return i < 3 ? (
              <OverlayTrigger
                key={`${i}-${user.name}`}
                placement='top'
                //@ts-ignore
                overlay={<Tooltip id='tooltip-user-name'>{user.name}</Tooltip>}
              >
                <div
                  className='symbol symbol-35px symbol-circle'
                  style={{cursor: isReadonly ? 'default' : 'pointer'}}
                >
                  {user?.avatar ? (
                    <img
                      src={process.env.REACT_APP_THEME_API_URL + '/storage/' + user.avatar}
                      className='symbol object-fit-cover'
                      style={{cursor: isReadonly ? 'default' : 'pointer'}}
                    />
                  ) : (
                    <span
                      className='symbol symbol-label bg-primary text-inverse-primary fw-bolder'
                      style={{cursor: isReadonly ? 'default' : 'pointer'}}
                    >
                      {user.name.substring(0, 1)}
                    </span>
                  )}
                </div>
              </OverlayTrigger>
            ) : null
          })}

        {users && users.length > 3 ? (
          <div className='symbol symbol-35px symbol-circle'>
            <span className='symbol symbol-label bg-primary text-inverse-primary fw-bolder'>
              {users.length}
            </span>
          </div>
        ) : null}

        {!isReadonly && (
          <div className='symbol symbol-35px symbol-circle'>
            <span className='symbol symbol-label bg-primary text-inverse-primary fw-bolder'>+</span>
          </div>
        )}
      </div>
      {/* 
      //@ts-ignore */}
      <StyledPopper
        id={value?.toString()}
        open={open}
        anchorEl={anchorEl}
        placement='top-end'
        disablePortal={true}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box
              sx={{
                borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                padding: '8px 10px',
                fontWeight: 600,
              }}
            >
              {intl.formatMessage({id: 'FORM.INPUT.NAME.ADDUSER_TASK'})}
            </Box>
            <Autocomplete
              open
              multiple
              onClose={(event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
                if (reason === 'escape') {
                  handleClose()
                }
              }}
              value={pendingValue}
              onChange={(event, newValue, reason, e) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return
                }

                taskresponsible_adjust({user_id: e?.option.user_id, task_id: taskId}).then(
                  (response) => {
                    queryClient.invalidateQueries()
                  }
                )

                setPendingValue(newValue)
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText='No labels'
              renderOption={(props, option, {selected}) => (
                <li {...props}>
                  {option.task_responsible_id !== null ? (
                    <Box
                      component={DoneIcon}
                      sx={{width: 17, height: 17, mr: '15px', ml: '-2px'}}
                      style={{
                        visibility: selected ? 'hidden' : 'visible',
                      }}
                    />
                  ) : (
                    <Box
                      component={DoneIcon}
                      sx={{width: 17, height: 17, mr: '15px', ml: '-2px'}}
                      style={{
                        visibility: selected ? 'visible' : 'hidden',
                      }}
                    />
                  )}

                  <Box>
                    {option.avatar != null ? (
                      <Box
                        component='img'
                        sx={{
                          width: 20,
                          height: 20,
                          flexShrink: 0,
                          borderRadius: '3px',
                          mr: 1,
                          mt: '2px',
                        }}
                        src={process.env.REACT_APP_THEME_API_URL + '/storage/' + option.avatar}
                      />
                    ) : (
                      <div className='symbol symbol-20px symbol-circle'>
                        <span className='symbol symbol-label bg-primary text-inverse-primary fw-bolder me-2'>
                          {option.name.substring(0, 1)}
                        </span>
                      </div>
                    )}
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      '& span': {
                        color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                      },
                    }}
                  >
                    {option.name}
                    <br />
                  </Box>

                  {option.task_responsible_id !== null ? (
                    <Box
                      component={CloseIcon}
                      sx={{opacity: 0.6, width: 18, height: 18}}
                      style={{
                        visibility: selected ? 'hidden' : 'visible',
                      }}
                    />
                  ) : (
                    <Box
                      component={CloseIcon}
                      sx={{opacity: 0.6, width: 18, height: 18}}
                      style={{
                        visibility: selected ? 'visible' : 'hidden',
                      }}
                    />
                  )}
                </li>
              )}
              options={usersAll?.sort((a, b) => {
                // Display the selected labels first.
                let ai = usersAll.indexOf(a)
                ai = ai === -1 ? usersAll.length + usersAll.indexOf(a) : ai
                let bi = usersAll.indexOf(b)
                bi = bi === -1 ? usersAll.length + usersAll.indexOf(b) : bi
                return ai - bi
              })}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.SELECTUSER_TEAMS'})}
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  )
}

export {UsersSymbolGroupColumn}
