// @ts-nocheck
import {Column} from 'react-table'
import {useIntl} from 'react-intl'
import {TaskByUserCustomHeader} from './TaskByUserCustomHeader'
import {Task} from '../../core/_models'
import { TaskByUserIdCell } from './TaskByUserIdCell'
import { TaskByUserParticipantsCell } from './TaskByUserParticipantsCell'
import { TaskByUserNameCell } from './TaskByUserNameCell'
import { TaskByUserProjectCell } from './TaskByUserProjectCell'
import { TaskByUserTimeTrackingCell } from './TaskByUserTimeTrackingCell'
import { TaskByUserStatusCell } from './TaskByUserStatusCell'
import { TaskByUserActionsCell } from './TaskByUserActionsCell'
import { TaskByUserDndHandleCell } from './TaskByUserDndHandleCell'

const tasksColumns: ReadonlyArray<Column<Task>> = [
  {
    Header: (props) => <TaskByUserCustomHeader tableProps={props} className='pt-0' />,
    id: 'dnd-handle',
    Cell: <TaskByUserDndHandleCell />,
  },
  {
    Header: (props) => <TaskByUserCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'TABLE.GENERAL.LABEL.ID'})} className='pt-0 min-w-50px' />,
    id: 'id',
    Cell: ({...props}) => <TaskByUserIdCell rowIndex={props.row.index} columnId={props.column.id}  task_id={props.data[props.row.index].id}/>,
  },
  {
    Header: (props) => <TaskByUserCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'TABLE.GENERAL.LABEL.PARTICIPANTS'})} className='pt-0 min-w-150px w-200px' />,
    id: 'users',
    Cell: ({...props}) => <TaskByUserParticipantsCell users={props.data[props.row.index].users} usersAll={props.data[props.row.index].usersAll} task_id={props.data[props.row.index].id}/>,
  },
  {
    Header: (props) => <TaskByUserCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'TABLE.GENERAL.LABEL.NAME'})} className='pt-0 min-w-350px' />,
    id: 'name',
    Cell: ({...props}) => <TaskByUserNameCell rowIndex={props.row.index} columnId={props.column.id}  task_id={props.data[props.row.index].id} name={props.data[props.row.index].name} />,
  },
  {
    Header: (props) => <TaskByUserCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'TABLE.GENERAL.LABEL.CLIENT_AND_PROJECT'})} className='pt-0 min-w-250px' />,
    id: 'project',
    Cell: ({...props}) => <TaskByUserProjectCell rowIndex={props.row.index} columnId={props.column.id}  task_id={props.data[props.row.index].id} name={props.data[props.row.index].name} project_name={props.data[props.row.index].project_name} project_id={props.data[props.row.index].project_id} clientName={props.data[props.row.index].clientName} clientId={props.data[props.row.index].clientId}/>,
  },

  {
    Header: (props) => <TaskByUserCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'TABLE.GENERAL.LABEL.TIMETRACKING'})} className='pt-0 min-w-50px' />,
    id: 'timetracking',
    Cell: ({...props}) => <TaskByUserTimeTrackingCell sequenceResponsibles={props.data[props.row.index].sequence_responsibles} rowIndex={props.row.index} columnId={props.column.id}  task_id={props.data[props.row.index].id} status={props.data[props.row.index].status} play={props.data[props.row.index].play}  time={props.data[props.row.index].total_time} name={props.data[props.row.index].name}  />,
  },
    
  {
    Header: (props) => (<TaskByUserCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'TABLE.GENERAL.LABEL.STATUS'})} className='pt-0 ps-0 min-w-100px text-center' />),
    id: 'status',
    Cell: ({...props}) => <TaskByUserStatusCell rowIndex={props.row.index} columnId={props.column.id} task_id={props.data[props.row.index].id} status={props.data[props.row.index].status} color={props.data[props.row.index].color}/>,
  },

  {
    Header: (props) => (<TaskByUserCustomHeader tableProps={props} title={useIntl().formatMessage({id: 'TABLE.GENERAL.LABEL.ACTIONS'})} className={'pt-0 min-w-100px text-center'} />),
    id: 'actions',
    Cell: ({...props}) => <TaskByUserActionsCell sequenceResponsibles={props.data[props.row.index].sequence_responsibles} taskId={props.data[props.row.index].id} name={props.data[props.row.index].name} status={props.data[props.row.index].status}/>,
  }
]

export {tasksColumns}
