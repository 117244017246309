/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, useRef} from 'react'
import {ID} from '../../../../../../_metronic/helpers'
import clsx from 'clsx'
import {useMutation, useQueryClient} from 'react-query'
import {deliveryTask, playTimeTracking, stopTimeTracking} from '../../../core/_requests'
import {Toast} from '../../../../../../_metronic/partials/widgets'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import {useQueryResponse} from '../../../core/QueryResponseProvider'
import {useListView} from '../../../core/ListViewProvider'
import {UserModel, useAuth} from '../../../../auth'

type Props = {
  task_id: ID
  time: number
  play: string
  rowIndex: number
  columnId: number
  updateCellData: (rowIndex: number, columnId: number, value: string) => void
  status: string
  name: string
  sequenceResponsibles: UserModel[]
}

const TaskByUserTimeTrackingCell: FC<Props> = ({
  task_id,
  time,
  play,
  rowIndex,
  columnId,
  updateCellData,
  status,
  name,
  sequenceResponsibles,
}) => {
  const {setCurrentUser} = useAuth()
  const [statustask, setStatusTask] = useState(status)
  const [time2, setTime] = useState(time!)
  const [play2, setPlay] = useState(play)
  const countRef = useRef()
  const [stopwatch, setStopwatch] = useState(0)
  const intl = useIntl()
  const queryClient = useQueryClient()
  const {refetch} = useQueryResponse()
  const {setItemIdForUpdate} = useListView()
  const TemaAtual = window.localStorage.getItem('kt_theme_mode_value') || ''

  useEffect(() => {
    if (play === 'run') {
      let intervalId: NodeJS.Timer
      const newTime = time > time2 ? time : time2 + 1
      intervalId = setInterval(() => setTime(newTime), 1000)
      return () => clearInterval(intervalId)
    } else {
      setStopwatch(time2)
    }

    return () => clearInterval(countRef.current)
  }, [play, time2])

  const hours = Math.floor(time2 / 3600)
  const minutes = Math.floor((time2 % 3600) / 60)
  const seconds = time2 % 60

  const startAndStop = () => {
    //Start, envia o tempo para Insert/Update na API
    if (play === 'pause') {
      playItem.mutateAsync()
    }
    // //Pausou, envia o tempo para insert/update na API
    else {
      stopItem.mutateAsync()
    }
  }

  const playItem = useMutation(() => playTimeTracking(task_id), {
    onSuccess: (response) => {
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.SUCCESS_TIMETRACKING_START'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TIMETRACKING'})}
        ),
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })

      queryClient.invalidateQueries()
      setCurrentUser((user) => {
        return {...user, has_active_time_tracking: true} as UserModel
      })
      setPlay('run')
    },
  })

  const stopItem = useMutation(() => stopTimeTracking(task_id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: (response) => {
      Toast.fire({
        icon: 'success',
        title: intl.formatMessage(
          {id: 'ALERT.LABEL.SUCCESS_TIMETRACKING_STOP'},
          {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TIMETRACKING'})}
        ),
        customClass: {
          popup: 'bg-light-success',
          title: 'text-success',
        },
      })

      queryClient.invalidateQueries()
      setCurrentUser((user) => {
        return {...user, has_active_time_tracking: false} as UserModel
      })
      setPlay('pause')
    },
  })

  const entregatask = async () => {
    const CurrentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''
    let tema = ''
    if (CurrentTheme === 'dark') {
      tema = '#353b48'
    } else {
      tema = '#fff'
    }

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    })

    swalWithBootstrapButtons
      .fire({
        title: intl.formatMessage({id: 'ALERT.TITLE.TASK.DELIVERY'}),
        icon: 'warning',
        text:
          sequenceResponsibles.length > 0
            ? 'Esta tarefa possui outros membros na sequência. Ao entregar, ela será transferida para o próximo responsável. Deseja entregar sua parte?'
            : '',
        background: tema,
        showCancelButton: true,
        confirmButtonText: sequenceResponsibles.length > 0 ? 'Sim, entregar minha parte' : 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          return await deliveryItem.mutateAsync()
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Toast.fire({
            icon: 'warning',
            title: intl.formatMessage(
              {id: 'ALERT.LABEL.ERROR_TASK_CLOSE_ITEM'},
              {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
            ),
            customClass: {
              popup: 'bg-light-danger',
              title: 'text-danger',
            },
          })
        }
      })
  }

  const deliveryItem = useMutation(() => deliveryTask(task_id), {
    onSuccess: (response) => {
      Toast.fire({
        icon: response.status === 'success' ? 'success' : 'error',
        title: response.message,
        customClass: {
          popup: response.status === 'success' ? 'bg-light-success' : 'bg-light-error',
          title: TemaAtual === 'dark' ? 'text-dark' : 'text-success',
        },
      })

      if (play === 'run')
        setCurrentUser((user) => ({...user, has_active_time_tracking: false} as UserModel))

      cancel(true)
      queryClient.invalidateQueries()
    },
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  return (
    <>
      {name !== '' && (
        <div className='d-flex flex-column justify-content-center'>
          {/*< TIMETRACKING />*/}
          <div className='row'>
            <div
              className='col-md-6 columnTimeTracking '
              style={{display: 'inline-flex', gap: '20px', alignItems: 'center'}}
            >
              {statustask !== 'Entregue' && (
                <div
                  className='symbol symbol-35px symbol-circle timetracking_button'
                  onClick={startAndStop}
                >
                  {play === 'run' ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='30'
                      height='30'
                      fill='currentColor'
                      className='bi bi-pause-circle cursor-pointer '
                      viewBox='0 0 16 16'
                      style={{color: '#50b840'}}
                    >
                      <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                      <path d='M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z' />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='30'
                      height='30'
                      fill='currentColor'
                      className='bi bi-play-circle cursor-pointer '
                      viewBox='0 0 16 16'
                      style={{color: '#009ef7'}}
                    >
                      <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                      <path d='M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z' />
                    </svg>
                  )}
                </div>
              )}

              {statustask !== 'Entregue' && (
                <div
                  className='symbol symbol-35px symbol-circle timetracking_button'
                  onClick={entregatask}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='30'
                    height='30'
                    fill='currentColor'
                    className='bi bi-check2-circle cursor-pointer '
                    viewBox='0 0 16 16'
                    style={{color: '#007dbb'}}
                  >
                    <path d='M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z' />
                    <path d='M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z' />
                  </svg>
                </div>
              )}

              {play === 'run' ? (
                <p className={clsx('bg-transparent fs-3 mb-0')} style={{color: '#50b840'}}>
                  {hours}:{minutes.toString().padStart(2, '0')}:
                  {seconds.toString().padStart(2, '0')}
                  {/*:{milliseconds.toString().padStart(2, "0")}*/}
                </p>
              ) : (
                <p className={clsx('bg-transparent fs-3 mb-0')} style={{color: '#009ef7'}}>
                  {hours}:{minutes.toString().padStart(2, '0')}:
                  {seconds.toString().padStart(2, '0')}
                  {/*:{milliseconds.toString().padStart(2, "0")}*/}
                </p>
              )}
            </div>
          </div>
          {/*< TIMETRACKING />*/}
        </div>
      )}
    </>
  )
}

export {TaskByUserTimeTrackingCell}
