import axios, {AxiosResponse} from 'axios'
import {ID, QueryState, Response} from '../../../../../../_metronic/helpers'
import {ResponseTask, Task, TasksQueryResponse} from './_models'
import {ResponseTaskAttachement, TaskAttachement} from '../TaskAttachment/core/_models'
import {tasksColumns} from '../../../../../../_metronic/partials/modals/adjust-time/components/tabs/components/content/adjust-task/components/tasks-list/table/columns/_columns'

const API_URL = process.env.REACT_APP_THEME_API_URL
const GET_TASK_URL = `${API_URL}/api/tarefas`
const GET_TASKATTACHEMENT_URL = `${API_URL}/api/tarefas/anexo`

const getTaskById = (id: ID): Promise<ResponseTask> => {
  return axios
    .get(`${GET_TASK_URL}/${id}`)
    .then((response: AxiosResponse<ResponseTask>) => response.data)
}

const getTaskAdjustTimeById = (id: ID): Promise<ResponseTask> => {
  return axios
    .get(`${GET_TASK_URL}/${id}/view-adjust-time`)
    .then((response: AxiosResponse<ResponseTask>) => response.data)
}

const getTasksList = (query: string): Promise<TasksQueryResponse> => {
  return axios
    .get(`${GET_TASK_URL}?${query}`)
    .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const updateNameTask = (task_id: ID, query: string): Promise<TasksQueryResponse> => {
  return axios
    .get(`${GET_TASK_URL}/${task_id}/${query}/updatenamecell`)
    .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const updateStatusTask = (task_id: ID, query: string): Promise<TasksQueryResponse> => {
  return axios
    .get(`${GET_TASK_URL}/${task_id}/${query}/updatestatuscell`)
    .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const updateOrderTask = (tasks: {id: number; order: number}[]): Promise<TasksQueryResponse> => {
  return axios
    .put(`${GET_TASK_URL}/updateordercell`, {tasks: tasks})
    .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const createTask = (task: Task): Promise<ResponseTask | undefined> => {
  return axios
    .post(`${GET_TASK_URL}/novo`, task)
    .then((response: AxiosResponse<ResponseTask>) => response.data)
}

const createTaskRow = (value: string, project_id: ID): Promise<void> => {
  return axios.get(`${GET_TASK_URL}/${value}/${project_id}/new-row`).then(() => {})
}

const cloneTask = (task: Partial<Task>): Promise<ResponseTask> => {
  let formData = new FormData()

  if (task.creator_id) {
    formData.append('creator_id', task.creator_id)
  }
  if (task.name) {
    formData.append('name', task.name)
  }
  if (task.description) {
    formData.append('description', task.description)
  }
  if (task.expected_date) {
    formData.append('expected_date', task.expected_date)
  }
  if (task.file) {
    task.file.map((file) => {
      formData.append('documents[]', file)
    })
  }
  if (task.checklist_concluded) {
    formData.append('checklist_concluded', JSON.stringify(task.checklist_concluded))
  }
  if (task.checklist_name) {
    formData.append('checklist_name', JSON.stringify(task.checklist_name))
  }
  if (task.project_id) {
    formData.append('project_id', task.project_id)
  }
  if (task.users) {
    formData.append('users', JSON.stringify(task.users))
  }
  if (task.status) {
    formData.append('status', task.status)
  }
  if (task.sprintId) {
    formData.append('sprintId', task.sprintId.toString())
  }
  if (task.tags) {
    formData.append('tags', JSON.stringify(task.tags))
  }
  if (task.sequence_responsibles) {
    const sequence_responsibles = task.sequence_responsibles.reduce(
      (acc: any, user: any, index: any) => {
        acc[Number(user.user_id ?? user.id)] = {order: index}
        return acc
      },
      {} as {[key: number]: {order: number}}
    )
    formData.append('sequence_responsibles', JSON.stringify(sequence_responsibles))
  }
  if (!task.users) formData.append('users', JSON.stringify(task.sequence_responsibles))

  return axios
    .post(`${GET_TASK_URL}/clone-task`, formData)
    .then((response: AxiosResponse<ResponseTask>) => response.data)
}

const updateTask = (task: Task): Promise<ResponseTask> => {
  if (task.sequence_responsibles) {
    const sequence_responsibles = task.sequence_responsibles.reduce(
      (acc: any, user: any, index: any) => {
        acc[Number(user.user_id ?? user.id)] = {order: index}
        return acc
      },
      {} as {[key: number]: {order: number}}
    )
    task.sequence_responsibles = sequence_responsibles
  }

  if (!task.priority) task.priority = null

  return axios
    .put(`${GET_TASK_URL}/${task.id}/editar`, task)
    .then((response: AxiosResponse<Response<ResponseTask>>) => response.data)
    .then((response: Response<ResponseTask>) => response.data)
}

const getUsersByTaskId = (taskId: ID): Promise<ResponseTask> => {
  return axios
    .get(`${GET_TASK_URL}/${taskId}/getUsers`)
    .then((response: AxiosResponse<ResponseTask>) => response.data)
}

const getTasksByProjectId = (id: string, query: string): Promise<TasksQueryResponse> => {
  return axios
    .get(`${GET_TASK_URL}/${id}/by-project?${query}`)
    .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const deliveryTask = (taskId: ID): Promise<ResponseTask> => {
  return axios
    .get(`${GET_TASK_URL}/${taskId}/delivery-task`)
    .then((d: AxiosResponse<ResponseTask>) => d.data)
}

const deleteTask = (taskId: ID): Promise<void> => {
  return axios.delete(`${GET_TASK_URL}/${taskId}/excluir`).then(() => {})
}

const deleteTaskInSprint = (taskId: ID, sprintId: ID): Promise<void> => {
  return axios.delete(`${GET_TASK_URL}/${taskId}/${sprintId}/excluir-sprint`).then(() => {})
}

const deleteSelectedTasks = (taskIds: Array<ID>): Promise<void> => {
  const requests = taskIds.map((id) => axios.delete(`${GET_TASK_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const searchTask = (search: string, project_id: ID): Promise<TasksQueryResponse> => {
  let formData = new FormData()

  formData.append('search', search)
  formData.append('project_id', project_id!.toString())

  return axios
    .post(`${GET_TASK_URL}/search`, formData)
    .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const getAttachements = (id: ID): Promise<TasksQueryResponse> => {
  return axios
    .get(`${GET_TASKATTACHEMENT_URL}/${id}`)
    .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const createTaskAttachement = (
  taskAttachement: TaskAttachement
): Promise<ResponseTaskAttachement> => {
  let formData = new FormData()
  if (taskAttachement.file) {
    formData.append('file', taskAttachement.file)
  }
  if (taskAttachement.task_id) {
    formData.append('task_id', taskAttachement.task_id)
  }
  return axios
    .post(`${GET_TASKATTACHEMENT_URL}/novo`, formData)
    .then((response: AxiosResponse<ResponseTaskAttachement>) => response.data)
}

const deleteAttachement = (attachementid: ID): Promise<void> => {
  return axios.delete(`${GET_TASKATTACHEMENT_URL}/${attachementid}/excluir`).then(() => {})
}

const playTimeTracking = (taskId: ID): Promise<TasksQueryResponse> => {
  return axios
    .get(`${GET_TASK_URL}/${taskId}/timetracking/start`)
    .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

const stopTimeTracking = (taskId: ID): Promise<TasksQueryResponse> => {
  return axios
    .get(`${GET_TASK_URL}/${taskId}/timetracking/stop`)
    .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
}

export {
  deleteTask,
  deleteTaskInSprint,
  deleteSelectedTasks,
  getTaskById,
  getUsersByTaskId,
  getTaskAdjustTimeById,
  createTask,
  updateTask,
  updateOrderTask,
  deliveryTask,
  cloneTask,
  getTasksByProjectId,
  searchTask,
  getAttachements,
  deleteAttachement,
  getTasksList,
  createTaskRow,
  updateNameTask,
  playTimeTracking,
  stopTimeTracking,
  createTaskAttachement,
  updateStatusTask,
}
