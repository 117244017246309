import {useQueryRequest} from '../../core/QueryRequestProvider'
import {MyTasksListSearchComponent} from './MyTasksListSearchComponent'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import {initialQueryState} from '../../../../../_metronic/helpers'

const MyTasksListHeader = () => {
  const {state, updateState} = useQueryRequest()

  return (
    <div className='card-header bg-body align-items-center border-0 py-6 justify-content-end'>
      <ul className='nav nav-pills me-md-6 mb-2 mb-sm-0'>
        <li
          className='nav-item m-0'
          onClick={() =>
            updateState({...initialQueryState, filter: {status: 'aberta', mode: 'table'}})
          }
        >
          <a
            className='btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3 active'
            data-bs-toggle='tab'
            href='#kt_tasks_project_list_pane'
          >
            <span className='svg-icon svg-icon-2'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z'
                  fill='currentColor'
                />
                <path
                  opacity='0.3'
                  d='M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </a>
        </li>
        <li
          onClick={() =>
            updateState({
              ...initialQueryState,
              filter: {mode: 'kanban'},
            })
          }
        >
          <a
            className='btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary'
            data-bs-toggle='tab'
            href='#kt_tasks_project_kanban_pane'
          >
            <ViewColumnIcon className='w-5 h-5' />
          </a>
        </li>
      </ul>
      {(state.filter as {mode: string}).mode == 'table' && <MyTasksListSearchComponent />}
    </div>
  )
}

export {MyTasksListHeader}
