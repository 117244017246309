import {FC, useState, InputHTMLAttributes, useEffect, useCallback} from 'react'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useQuery, useQueryClient, useMutation} from 'react-query'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ID, isNotEmpty, KTSVG, QUERIES, stringifyRequestQuery} from '../../../helpers'
import {createTask, getUsersInstanceTask} from './core/_requests'
import {Toast, AlertBoostrapButtons} from '../../widgets'
import {Loading} from '../../components/loading/Loading'
import {useAuth} from '../../../../app/modules/auth'
import {useQueryRequest} from '../../../../app/modules/project/components/core/QueryRequestProvider'
import {getTasksByUserId} from '../../../../app/modules/mytasks/core/_requests'
import {SelectAutoComplete} from '../../components/Select/SelectAutoComplete'
import {CreateProjectAndClient} from '../create-project-and-client/CreateProjectAndClientModal'
import {getStageById} from '../../../../app/modules/stages/components/core/_requests'
import {UsersSymbolGroup} from '../../../../app/modules/project/components/projects-list/UsersSymbolGroup'
import {updateUserTaskOnboarded} from './core/_requests'
import {UserModal} from '../user-create-modal-stepper/UserModal'
import {MuiAutocomplete} from '../../../../app/modules/wiki/components/newpost/components/MuiAutocomplete/MuiAutocomplete'
import {Wiki} from '../../../../app/modules/wiki/components/core/_models'
import {Tag} from './core/_models'
import {TagsAutoComplete} from './components/TagsAutoComplete'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {TaskSequenceResponsibles} from '../../../../app/modules/project/components/tasks-list/task-edit-modal/TaskSequenceResponsibles'
import {UsersSymbolGroupColumn} from '../../../../app/modules/project/components/tasks-list/UsersSymbolGroupColumn'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable'

type Props = {
  handleClose: () => void | undefined
  stageId: ID | string
  sprintId: ID | undefined
}

interface checkProps extends InputHTMLAttributes<HTMLInputElement> {
  content: string
  check: boolean
}

interface checkPropsFile extends InputHTMLAttributes<HTMLInputElement> {
  file: any
}

interface responsibeProps extends InputHTMLAttributes<HTMLInputElement> {
  image: string
  name: string
}

const TaskModalContentByUserId: FC<Props> = ({handleClose, stageId, sprintId}) => {
  const {mode} = useThemeMode()
  const intl = useIntl()
  const [checks, setChecks] = useState<Array<checkProps>>([])
  const [checksFile, setChecksFile] = useState<Array<checkPropsFile>>([])
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [status_name, setStatusName] = useState('')
  var today = new Date().toISOString().split('T')[0]
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const [showProjectAndClientModal, setShowProjectAndClientModal] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [showBtn, setShowBtn] = useState(false)
  const [showModalUser, setShowModalUser] = useState<boolean>(false)
  const [selectedOptions, setSelectedOptions] = useState([] as Tag[])

  const addCheck = () => {
    setChecks([...checks, {content: '', check: false}])
  }

  const addCheckFile = () => {
    setChecksFile([...checksFile, {file: ''}])
  }

  const removeCheckFile = (key: number) => {
    setChecksFile(checksFile.filter((value, index) => key !== index))
  }

  const removeCheck = (key: number) => {
    setChecks(checks.filter((value, index) => key !== index))
  }

  const {currentUser, setCurrentUser} = useAuth()

  const initialValues = {
    file: [],
    name: '',
    description: '',
    expected_date: '',
    project_id: '',
    checklist_concluded: [],
    checklist_name: [],
    users: [],
    tags: [],
    sequence_responsibles: [],
    priority: null,
  }

  const createAccountSchemas = [
    Yup.object().shape({
      name: Yup.string().required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
      project_id: Yup.string().required(intl.formatMessage({id: 'FORM.INPUT.VALIDATION.REQUIRED'})),
    }),
  ]

  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])

  useEffect(() => {
    formik.setFieldValue('creator_id', currentUser?.id)
    if (stageId !== undefined && typeof stageId != 'string') {
      ;(async () => {
        await getStageById(stageId).then((response) => {
          setStatusName(response.stages.name!)
          formik.setFieldValue('status', stageId)
          formik.setFieldValue('sprintId', sprintId)
        })
      })()
    } else {
      if (stageId) setStatusName(stageId[0].toUpperCase() + stageId.substring(1))
    }

    // Show notification in case it is first task and user has no cost_hour
    if (!currentUser?.task_onboarded && !currentUser?.cost_hour) {
      AlertBoostrapButtons.fire({
        html: `<h5>Você não inseriu seus dados de valor hora e jornada de trabalho. Essas informações são importantes para que o TaskRush forneça insights precisos sobre o desempenho de cada projeto.</h5><h5>Deseja cadastrar esses dados agora?</h5>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Cadastrar dados',
        cancelButtonText: 'Continuar sem esses dados',
        reverseButtons: false,
        customClass: {
          popup: 'bg-body w-100 mw-600px',
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-success',
        },
      }).then(async (result) => {
        await updateUserTaskOnboardedStatus.mutateAsync()
        if (result.isConfirmed) {
          return setShowModalUser(true)
        }
      })
    }
  }, [])

  const {
    isLoading,
    data: dataConst,
    error,
  } = useQuery(
    `${QUERIES.TASKS_LIST}-usersTask`,
    () => {
      return getUsersInstanceTask()
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
      refetchOnWindowFocus: false,
    }
  )

  const handleCreateProjectAndClient = (newProjectName: string) => {
    setSearchParams({new_project_name: newProjectName})
    setShowProjectAndClientModal(true)
  }

  const updateUserTaskOnboardedStatus = useMutation(() => updateUserTaskOnboarded(true), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: (response) => {
      if (!currentUser?.task_onboarded) {
        setCurrentUser({...currentUser!, task_onboarded: 1})
      }
    },
    onError: (error: any) => {
      console.error(error)
    },
  })

  const formik = useFormik({
    initialValues,
    validationSchema: currentSchema,
    onSubmit: async (values, {setSubmitting, resetForm}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.name)) {
          await createTask(values).then((response) => {
            Toast.fire({
              icon: 'success',
              title: intl.formatMessage(
                {id: 'ALERT.LABEL.SUCCESS_TASK_CREATE_ITEM'},
                {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
              ),
              customClass: {
                popup: 'bg-light-success',
                title: 'text-success',
              },
            })
            queryClient.invalidateQueries()
            resetForm()
            navigate(`?taskId=${response.tasks}`)
          })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        queryClient.invalidateQueries()
        cancel(true)
      }
    },
  })

  console.log(formik.values.priority);

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
  }

  const {refetch, data: response} = useQuery(
    `${QUERIES.TASKS_LIST}`,
    () => {
      return getTasksByUserId(query)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const submitForm = useCallback(
    async (e: any) => {
      formik.setFieldValue('tags', selectedOptions)

      formik.setFieldValue(
        'checklist_concluded',
        checks.map((check, index) => {
          return check.check
        })
      )

      formik.setFieldValue(
        'checklist_name',
        checks.map((check, index) => {
          return check.content
        })
      )

      formik.setFieldValue(
        'file',
        checksFile.map((file, index) => {
          return file.file
        })
      )

      if (typeof stageId == 'string') formik.setFieldValue('status_name', status_name)

      formik.submitForm()
      handleClose()
    },
    [formik]
  )

  return (
    <>
      <div className='modal-content'>
        <form
          id='kt_modal_add_project_milestone_form'
          className='form'
          onSubmit={(e) => e.preventDefault()}
          noValidate
        >
          <div className='modal-header border-0'>
            <div className='col-md-8'>
              <input
                placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.TASK_TITLE'})}
                type='text'
                className={clsx('form-control py-2 bg-transparent border-0 fs-1')}
                autoComplete='off'
                disabled={formik.isSubmitting}
                {...formik.getFieldProps('name')}
                onChange={(e) => {
                  formik.setFieldValue('name', e.target.value)
                }}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
            </div>
            <div className='col-md-3 d-none d-md-flex justify-content-end pe-4'>
              {formik.values.sequence_responsibles.length > 0 ? (
                <UsersSymbolGroupColumn
                  users={formik.values.sequence_responsibles}
                  usersAll={dataConst?.users!}
                  isReadonly={true}
                />
              ) : dataConst?.users?.length! > 0 ? (
                <UsersSymbolGroup
                  users={dataConst?.users!}
                  classes='justify-content-end'
                  onChange={(users) => formik.setFieldValue('users', users)}
                />
              ) : null}
            </div>
            <button
              className='btn btn-sm btn-icon btn-active-color-primary h-auto ms-4'
              onClick={(e) => {
                if (e.detail === 0) return
                handleClose()
              }}
            >
              <KTSVG
                className='svg-icon-2x svg-icon-gray-800'
                path='/media/icons/duotune/arrows/arr061.svg'
              />
            </button>
          </div>
          <div className='modal-body d-flex flex-column flex-lg-row justify-content-between'>
            <div className='col-lg-8 pe-5 pb-5 pb-lg-0 mb-15 mb-lg-0 border-bottom border-bottom-lg-0 d-flex flex-column'>
              <ul
                className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap '
                role='tablist'
              >
                <li className='nav-item'>
                  <a
                    className='nav-link cursor-pointer text-gray-900 active'
                    data-bs-toggle='tab'
                    href='#kt_task_description_pane'
                  >
                    {intl.formatMessage({id: 'TASK.TABLE.DESCRIPTION'})}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link cursor-pointer text-gray-900'
                    data-bs-toggle='tab'
                    href='#kt_task_attachments_pane'
                  >
                    {intl.formatMessage({id: 'TASK.TABLE.ATTACHMENTS'})}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link cursor-pointer text-gray-900'
                    data-bs-toggle='tab'
                    href='#kt_task_checklist_pane'
                  >
                    {intl.formatMessage({id: 'TASK.TABLE.CHECKLIST'})}
                  </a>
                </li>
                <li className='nav-item align-items-center'>
                  <a
                    className='nav-link cursor-pointer text-gray-900'
                    data-bs-toggle='tab'
                    href='#kt_task_responsible_sequence_pane'
                  >
                    {intl.formatMessage({id: 'TASK.TABLE.RESPONSIBLE_SEQUENCE'})}
                  </a>
                  <OverlayTrigger
                    placement='top'
                    delay={{show: 50, hide: 100}}
                    overlay={
                      <Tooltip>
                        Crie uma sequência de responsáveis e organize a passagem de bastão da
                        tarefa. Assim, ao entregar uma parte, a tarefa será transferida de forma
                        automática para o próximo usuário.
                      </Tooltip>
                    }
                  >
                    <i className='fas fa-exclamation-circle ms-2 fs-7' />
                  </OverlayTrigger>
                </li>
              </ul>
              <div className='tab-content pt-3 h-100'>
                <div id='kt_task_description_pane' className='tab-pane fade show active h-100'>
                  <textarea
                    className='form-control border-0 fw-normal h-100'
                    id='description'
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('description')}
                    onChange={(e) => {
                      formik.setFieldValue('description', e.target.value)
                    }}
                    style={{resize: 'none'}}
                    rows={8}
                    placeholder={intl.formatMessage({id: 'TASK.TABLE.DESCRIPTION'})}
                  ></textarea>
                </div>

                <div id='kt_task_attachments_pane' className='tab-pane fade'>
                  {checksFile.map((check, key) => (
                    <div className='check d-flex align-items-center mb-4' key={key}>
                      <input
                        className='form-control form-control-solid py-2  rounded fw-normal'
                        type='file'
                        id='file'
                        accept='.png, .jpg, .jpeg, .pdf, .doc, .docx, .zip, .rar, .sql, .xls, .xlsx'
                        disabled={formik.isSubmitting}
                        onKeyPress={(e) => {
                          e.key === 'Enter' && e.preventDefault()
                        }}
                        onChange={(e) => {
                          setChecksFile(
                            checksFile.filter((c, index) => {
                              if (key === index) {
                                if (e.target.files) {
                                  c.file = e.target.files[0]
                                }
                              }
                              return c
                            })
                          )
                        }}
                      ></input>

                      <button onClick={() => removeCheckFile(key)} className='btn p-0 ms-5'>
                        <i className='bi bi-trash3-fill'></i>
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={addCheckFile}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    className='btn text-bold d-flex align-items-center p-0 mt-10'
                  >
                    <div className='d-flex align-items-center justify-content-center w-15px h-15px bg-gray-900 me-3'>
                      <i className='bi bi-plus text-gray-100 fs-5 p-0'></i>
                    </div>
                    {intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                  </button>
                </div>

                <div id='kt_task_checklist_pane' className='tab-pane fade'>
                  <div className='checklist pt-6'>
                    {checks.map((check, key) => (
                      <div className='check d-flex align-items-center mb-4' key={key}>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id={key.toString()}
                          {...formik.getFieldProps('checklist_concluded')}
                          onChange={(e) =>
                            setChecks(
                              checks.filter((c, index) => {
                                if (key === index) check.check = e.target.checked
                                return c
                              })
                            )
                          }
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                        />

                        <input
                          className='form-control border-0 rounded-0 bg-gray-100 bg-focus-gray-100 p-0 ps-2 py-2 ms-4'
                          type='text'
                          placeholder={intl.formatMessage({id: 'CHECKLIST.NAME_NEW'})}
                          id='checklist_name'
                          disabled={formik.isSubmitting}
                          onKeyPress={(e) => {
                            e.key === 'Enter' && e.preventDefault()
                          }}
                          {...formik.getFieldProps('checklist_name')}
                          value={check.content}
                          onChange={(e) => {
                            setChecks(
                              checks.filter((c, index) => {
                                if (key === index) {
                                  c.content = e.target.value
                                }
                                return c
                              })
                            )
                          }}
                        ></input>

                        <button onClick={() => removeCheck(key)} className='btn p-0 ms-5'>
                          <i className='bi bi-trash3-fill'></i>
                        </button>
                      </div>
                    ))}
                    <button
                      onClick={addCheck}
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault()
                      }}
                      className='btn text-bold d-flex align-items-center p-0 mt-10'
                    >
                      <div className='d-flex align-items-center justify-content-center w-15px h-15px bg-gray-900 me-3'>
                        <i className='bi bi-plus text-gray-100 fs-5 p-0'></i>
                      </div>
                      {intl.formatMessage({id: 'GENERAL.ADD_ITEM'})}
                    </button>
                  </div>
                </div>

                {/* sequence responsible */}
                {dataConst?.users && (
                  <TaskSequenceResponsibles
                    users={dataConst?.users}
                    isCreate={true}
                    formikSetFieldValue={formik.setFieldValue}
                    responsibles={formik.values.sequence_responsibles}
                  />
                )}
              </div>
            </div>
            <div className='col-lg-4 border-start-lg ps-lg-10'>
              <div className='border-bottom pb-8'>
                <div className='d-flex mb-4'>
                  <span className='fw-bold text-dark me-1'>
                    {intl.formatMessage({id: 'TASK.LABEL.ESTIMATED_DEADLINE'})}
                  </span>
                </div>

                <div className='d-flex mb-4'>
                  <input
                    placeholder={intl.formatMessage({id: 'FORM.INPUT.NAME.BEGIN_DATE'})}
                    type='date'
                    min={today}
                    disabled={formik.isSubmitting}
                    {...formik.getFieldProps('expected_date')}
                    onKeyPress={(e) => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    className={clsx('form-control form-control-solid py-2  rounded fw-normal')}
                    autoComplete='off'
                  />
                </div>

                <div className='d-flex mb-4'>
                  <span className='fw-bold text-dark me-1'>
                    {intl.formatMessage({id: 'TASK.LABEL.CREATOR_DEADLINE'})} {currentUser?.name}
                  </span>
                </div>
                {stageId && (
                  <div className='d-flex mb-4'>
                    <span className='fw-bold text-dark me-1'>
                      {intl.formatMessage({id: 'TASK.LABEL.STATUS'})} {status_name}
                    </span>
                  </div>
                )}
              </div>

              <div className='pt-10'>
                <div>
                  <label className='fw-bold text-dark me-1'>Prioridade:</label>
                  <CreatableSelect
                    isClearable
                    placeholder={'Selecione a prioridade'}
                    options={[{label: 'Baixa', value:'baixa'}, {label: 'Média', value: 'media'}, {label: 'Alta', value: 'alta'}, {label: 'Crítica', value: 'critica'}]}
                    onChange={(newValue) => formik.setFieldValue('priority', newValue?.value)}
                    classNames={{
                      control: (state) => 'form-control form-control-solid py-1',
                      input: (state) => 'text-dark',
                      singleValue: (state) => 'text-dark',
                      menu: (state) => 'bg-body',
                    }}
                    styles={{
                      option: (styles, {data, isDisabled, isFocused, isSelected}) => {
                        return {
                          ...styles,
                          backgroundColor: isDisabled
                            ? undefined
                            : isSelected
                            ? '#369bff70'
                            : isFocused
                            ? '#369bff70'
                            : undefined,
                          color: isDisabled ? '#ccc' : isSelected ? '#181C32' : undefined,
                        }
                      },
                      clearIndicator(styles, props) {
                        return {
                          ...styles,
                          cursor: 'pointer'
                        }
                      },
                    }}
                  />
                </div>
              </div>

              <div className='pt-4'>
                <div>
                  <label className='fw-bold text-dark me-1'>Tags:</label>
                  <TagsAutoComplete
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                  />
                </div>
              </div>

              <div className='pt-4'>
                <div>
                  <span className='fw-bold text-dark me-1'>
                    {intl.formatMessage({id: 'TASK.LABEL.PROJECT'})}
                  </span>
                  <SelectAutoComplete
                    endPoint='projetos'
                    placeholder={intl.formatMessage({id: 'FORM.INPUT.TOOLTIP.PROJECT'})}
                    onChange={(e: any) => {
                      formik.setFieldValue('project_id', e?.value)
                      setShowBtn(true)
                    }}
                    onCreateOption={(inputValue) => handleCreateProjectAndClient(inputValue)}
                  />
                </div>
              </div>

              <div className='text-center pt-7'>
                <button
                  type='button'
                  className='btn btn-primary btn-sm px-8'
                  data-kt-users-modal-action='submit'
                  onClick={submitForm}
                  disabled={formik.isSubmitting || !formik.isValid || !formik.touched || !showBtn}
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'GENERAL.CREATE_TASK'})}
                  </span>

                  {formik.isSubmitting && (
                    <span className='indicator-progress'>
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
        {(formik.isSubmitting || updateUserTaskOnboardedStatus.isLoading) && <Loading />}
      </div>
      <CreateProjectAndClient
        show={showProjectAndClientModal}
        handleClose={() => setShowProjectAndClientModal(false)}
      />
      <UserModal
        show={showModalUser}
        handleClose={() => setShowModalUser(false)}
        userId={currentUser?.id}
      />
    </>
  )
}

export {TaskModalContentByUserId}
