import {useState} from 'react'
import {FormikErrors, FormikValues} from 'formik'
import {UsersSymbolGroup} from '../../projects-list/UsersSymbolGroup'
import {IconUserModel} from '../UsersSymbolGroupColumn'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {SequenceResponsiblesSymbolGroup} from './components/SequenceResponsiblesSymbolGroup'

type TaskSequenceResponsiblesProps = {
  isCreate?: boolean
  isClone?: boolean
  users: Array<IconUserModel>
  responsibles?: Array<IconUserModel>
  formikSetFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>
}

export function TaskSequenceResponsibles({
  isCreate = false,
  users,
  responsibles = [],
  formikSetFieldValue,
  isClone = false,
}: TaskSequenceResponsiblesProps) {
  const {mode} = useThemeMode()
  const [data, setData] = useState(responsibles)

  function removeResponsible(id: number) {
    //@ts-ignore
    const newUsers = data.filter((user) => (user.user_id ?? user.id) != id)
    setData(newUsers)
    formikSetFieldValue('sequence_responsibles', newUsers)
  }

  function getAvatarURL(avatar?: string) {
    if (avatar && avatar !== 'null')
      return process.env.REACT_APP_THEME_API_URL + '/storage/' + avatar

    return mode === 'dark'
      ? toAbsoluteUrl('/media/svg/files/blank-image-dark.svg')
      : toAbsoluteUrl('/media/svg/files/blank-image.svg')
  }

  return (
    <div
      id={isClone ? 'kt_task_responsible_sequence_pane_clone' : 'kt_task_responsible_sequence_pane'}
      className='tab-pane fade'
    >
      <div className='pt-6'>
        <div className='timeline'>
          {users.length > 0 ? (
            isCreate ? (
              <UsersSymbolGroup
                users={users}
                classes={`justify-content-start mb-4 ${data.length > 0 ? '' : 'ms-7'}`}
                onChange={(users: any) => {
                  formikSetFieldValue('sequence_responsibles', users)
                  formikSetFieldValue('users', users)
                  setData(users)
                }}
                hasDefaultValue={false}
                placement='bottom-start'
                responsibles={data}
              />
            ) : (
              <SequenceResponsiblesSymbolGroup
                classes={`justify-content-start mb-4 ${data.length > 0 ? '' : 'ms-7'}`}
                users={data}
                //@ts-ignore
                usersAll={users.filter((user) => user.task_concluded == 0)}
                onChange={(users: any) => {
                  formikSetFieldValue('sequence_responsibles', users)
                  formikSetFieldValue('users', users)
                  setData(users)
                }}
                placement='bottom-start'
              />
            )
          ) : null}

          {data?.map((user, key) => (
            <div className='timeline-item d-flex'>
              <div className='timeline-line w-40px'></div>
              <div className='timeline-icon symbol symbol-circle symbol-40px overflow-hidden me-4'>
                <div className='symbol-label bg-light'>
                  <span className='svg-icon svg-icon-2 svg-icon-gray-500'>
                    <img
                      alt={user.name}
                      className='w-100 object-fit-cover'
                      src={getAvatarURL(user?.avatar)}
                    />{' '}
                  </span>
                </div>
              </div>
              <div className='timeline-content mb-10 mt-3 d-flex align-items-center justify-content-between'>
                <div className='overflow-auto pe-3'>
                  <span className='fs-5 fw-semibold'>{user.name}</span>
                </div>
                <div>
                  {/* @ts-ignore */}
                  {user.task_concluded && !isClone ? (
                    <div className='btn p-0 ms-5'>
                      <i className='bi bi-check-square-fill text-success'></i>
                    </div>
                  ) : (
                    <button
                      //@ts-ignore
                      onClick={() => removeResponsible(Number(user.user_id ?? user.id))}
                      className='btn p-0 ms-5'
                    >
                      <i className='bi bi-x-square-fill'></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
