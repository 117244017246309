/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
  WithChildren,
} from '../../../../_metronic/helpers'
import {
  getTasksByUserId,
} from './_requests'
import {Task} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import {useParams} from 'react-router-dom'

const QueryResponseContext = createResponseContext<Task>(initialQueryResponse)
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))

  useEffect(() => {
    const stringQuery = stringifyRequestQuery(state)
    if (query !== stringQuery) {
      setQuery(stringQuery)
    }
  }, [state])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.TASKS_LIST}-${query}`,
    async () => {
      if ((state.filter as {mode: string}).mode == 'kanban') {
        const openedTasks = (await getTasksByUserId('filter_status=aberta')).data
        const closedTasks = (await getTasksByUserId('filter_status=fechada')).data
        return {
          data: [...openedTasks, ...closedTasks],
        }
      }

      return await getTasksByUserId(query)
    },
    {
      cacheTime: 0,
      keepPreviousData: false,
      refetchOnWindowFocus: true,
    }
  )

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()

  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
