import axios, {AxiosResponse} from 'axios'
import {ID, QueryState, Response} from '../../../../../../../../../_metronic/helpers'
import {ResponseTaskComment, TaskComment, TaskCommentsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const TASK_COMMENT_URL = `${API_URL}/api/task-comments`

// const createTaskComment =  (taskComment: TaskComment): Promise<ResponseTaskComment> => {
//   return axios
//       .post(`${GET_TASKATTACHEMENT_URL}/novo`, formData)
//       .then((response: AxiosResponse<ResponseTaskAttachement>) => response.data)
// }

const createTaskComment = (taskComment: TaskComment): Promise<ResponseTaskComment | undefined> => {
  return axios
    .post(TASK_COMMENT_URL, taskComment)
    .then((response: AxiosResponse<ResponseTaskComment>) => response.data)
}

const getTaskComments = (query: string): Promise<TaskCommentsQueryResponse> => {
  return axios
    .get(`${TASK_COMMENT_URL}?${query}`)
    .then((d: AxiosResponse<TaskCommentsQueryResponse>) => d.data)
}

// const deleteAttachement = (attachementid: ID): Promise<void> => {
//   return axios.delete(`${GET_TASKATTACHEMENT_URL}/${attachementid}/excluir`).then(() => {})
// }

// const getTaskAttachementById = (id: ID): Promise<TasksQueryResponse> => {
//   return axios
//       .get(`${GET_TASKATTACHEMENT_URL}/${id}`)
//       .then((d: AxiosResponse<TasksQueryResponse>) => d.data)
// }

export {createTaskComment, getTaskComments}
