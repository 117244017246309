/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import {initialQueryState, KTSVG, QueryState, useDebounce} from '../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {TagsAutoComplete} from '../../../../../_metronic/partials/modals/task/components/TagsAutoComplete'
import {Tag} from '../../../../../_metronic/partials/modals/task/core/_models'

const MyTasksListSearchComponent = () => {
  const {state, updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)
  const [currentFilter, setCurrentFilter] = useState('Aberta')
  const [selectedOptions, setSelectedOptions] = useState([] as Tag[])
  const [priority, setPriority] = useState<string>()

  useEffect(() => {
    updateState({
      search: debouncedSearchTerm?.length ? debouncedSearchTerm : currentFilter,
      ...initialQueryState,
    })
  }, [debouncedSearchTerm])

  useEffect(() => {
    updateState({
      filter: {...(state.filter as object), tags: selectedOptions.flatMap((tag) => tag.id)},
    })
  }, [selectedOptions])

  return (
    <div className='d-flex'>
      <div className='d-flex align-items-center position-relative me-5'>
        <select
          className='form-select form-select-sm form-control form-control-solid w-250px w-100 w-md-100px h-45px'
          id='priority'
          onChange={(e) => {
            const priority = e.target.value == 'todas' ? undefined : e.target.value
            setPriority(priority)
            updateState({filter: {...(state.filter as object), priority: priority}})
          }}
        >
          <option value='' disabled selected>
            Prioridade
          </option>
          <option value='baixa' selected={priority === 'baixa'}>
            Baixa
          </option>
          <option value='media' selected={priority === 'media'}>
            Média
          </option>
          <option value='alta' selected={priority === 'alta'}>
            Alta
          </option>
          <option value='critica' selected={priority === 'critica'}>
            Crítica
          </option>
          <option value='todas' selected={priority === 'todas'}>
            Todas
          </option>
        </select>
      </div>

      <div className='d-flex align-items-center my-1 me-5 w-100' style={{maxWidth: '22em'}}>
        <TagsAutoComplete
          actions={false}
          isSearchable={false}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          controlClass='ps-2 py-0 fs-6 min-h-45px'
        />
      </div>

      <div className='d-flex align-items-center position-relative me-5'>
        <select
          className='form-select form-select-sm form-control form-control-solid w-350px w-100 w-md-300px h-45px'
          id='period'
          onChange={(e) => {
            setCurrentFilter(e.target.value)
            updateState({filter: {...(state.filter as object), status: e.target.value}})
          }}
        >
          <option value='Aberta' selected={currentFilter === 'Aberta'}>
            Tarefas abertas
          </option>
          <option value='Fechada' selected={currentFilter === 'Fechada'}>
            Tarefas fechadas
          </option>
          <option value='CriadasPorMim' selected={currentFilter === 'CriadasPorMim'}>
            Tarefas criadas por mim
          </option>
        </select>
      </div>

      <div className='w-100 d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-3'
        />

        <input
          type='text'
          data-kt-client-table-filter='search'
          className='form-control form-control-solid w-350px ps-15 w-100 w-md-300px'
          placeholder='Buscar tarefa'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  )
}

export {MyTasksListSearchComponent}
